const $cards = $('.process_card');

function activateCard(index) {
  $cards.removeClass('active');
  $cards.eq(index).addClass('active');
}

$cards.on('click', function() {
  const index = $(this).index();
  activateCard(index);
});

activateCard(0);
