import Swiper from 'swiper';
import {Navigation, Pagination, EffectCreative, Autoplay, Scrollbar} from 'swiper/modules'
Swiper.use([Pagination, Navigation, EffectCreative, Autoplay, Scrollbar]);

const secondarySwiper = new Swiper('.slider-secondary', {
  spaceBetween: 50,
  slidesPerView: 1,
  loop: true,
  breakpoints: {
    1400: {
      slidesPerView: 1.2,
    },
  },
  scrollbar: {
    el: ".secondary-scrollbar",
    draggable: true,
  },
  navigation: {
    nextEl: '.secondary-swiper-button-next',
    prevEl: '.secondary-swiper-button-prev',
  },
});
