const $tabs = $('.our-story_tab');
const $images = $('.our-story_content .image');
const $contents = $('.our-story_tabs .content');

function activateTab(index) {
  $tabs.removeClass('active');
  $images.hide();
  $contents.hide();

  $tabs.eq(index).addClass('active');
  $images.eq(index).show();
  $contents.eq(index).show();
}

$tabs.on('click', function() {
  const index = $(this).index();
  activateTab(index);
});

activateTab(0);
