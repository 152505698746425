document.addEventListener('DOMContentLoaded', function() {
  const sections = document.querySelectorAll('.wp-block-acf-services');

  sections.forEach(section => {
    const tabs = section.querySelectorAll('.services_card');
    const contents = section.querySelectorAll('.services_content__wrapper');

    function activateTab(index) {
      tabs.forEach((tab, i) => {
        if (i === index) {
          tab.classList.add('active');
          contents[i].style.display = 'flex';
        } else {
          tab.classList.remove('active');
          contents[i].style.display = 'none';
        }
      });
    }

    tabs.forEach((tab, index) => {
      tab.addEventListener('click', () => {
        activateTab(index);
      });
    });

    activateTab(0);
  });
});
