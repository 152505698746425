import Swiper from 'swiper';
import {Navigation, Pagination, Autoplay, EffectFade, Scrollbar} from 'swiper/modules'
Swiper.use([Pagination, Navigation, Autoplay, EffectFade, Scrollbar]);

const imageSwiper = new Swiper('.cta-image-swiper', {
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  effect: "fade",
  fadeEffect: {
    crossFade: true
  },
  scrollbar: {
    el: ".scrollbar-our-work",
    draggable: true,
  },
  navigation: {
    nextEl: '.cta-image-swiper-button-next',
    prevEl: '.cta-image-swiper-button-prev',
  },
});
