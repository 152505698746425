const mobileMenuBreakpoint = 1200;
const $win = $(window);
const $body = $('body');
const $html = $('html');

const $header = $('.site-header');
const $headerMenu = $header.find('#menu-main-menu');
const $headerNav = $header.find('.wp-classic-menu-block');
let $previousItem = $()
const $menuItems = $('#menu-main-menu').children('.menu-item-has-children')

function NavigateMobileSubMenu(level, $subMenu) {
  $subMenu = $subMenu || null;
  if($subMenu) {
    $headerMenu.find('.sub-menu[data-level=' + level + ']').removeClass('active');
    $subMenu.addClass('active');
  }
  $headerMenu.css('transform', 'translate(' + (-100 * level) + '%)');
  $headerNav.attr('data-currentlevel', level);
}

$header.find('.sub-menu-back').click(function () {
  const level = $(this).attr('data-backlevel');
  NavigateMobileSubMenu(level);
});

$menuItems.on('click', function(event) {
  if ($(window).outerWidth() > 1200) {
    if ($(event.currentTarget).is('li')) {
      if (!$(this).hasClass('menu-item-arrow')) {
        event.preventDefault();
        $(this).addClass('menu-item-arrow');
      }
      if (!$(this).hasClass('menu-item-transition')) {
        if ($previousItem.hasClass('menu-item-arrow') && $previousItem[0] !== this) {
          $previousItem.removeClass('menu-item-arrow');
        }
        $previousItem.removeClass('menu-item-transition');
        $previousItem = $(this);
        $(this).addClass('menu-item-transition');
      }
    }
  } else {
    const $subMenu = $(this).find('> .sub-menu');
  }
});

$header.find('#menu-main-menu > .menu-item-has-children').each(function () {
  const $self = $(this);
  const $link = $self.find('> a');
  const $subMenu = $self.find('> .sub-menu');
  const $subMenuBack = $subMenu.find('> .sub-menu__back');
  const linkHref = $link.attr('href');
  if (linkHref !== '#' && linkHref !== '' && linkHref !== undefined) {
    const $menuItemDuplicate = $('<li class="menu-item menu-item--duplicate"></li>');
    $link.clone().appendTo($menuItemDuplicate);
    $subMenuBack.after($menuItemDuplicate);
  }

  $link.click(function (event) {
    if ($win.outerWidth() <= mobileMenuBreakpoint) {
      event.preventDefault();
      NavigateMobileSubMenu($subMenu.data('level'), $subMenu);
    }
  });
});

$(document).on('click', function(event) {
  if (!$(event.target).closest('#menu-main-menu').length) {
    if ($previousItem.hasClass('menu-item-transition')) {
      $previousItem.removeClass('menu-item-transition menu-item-arrow');
    }
  }
});

$('.mobile-close, .mobile-menu-toggle').click(function () {
  $html.toggleClass('open')
  if($html.hasClass('open')) {
    $win.scrollTop(0)
  }
  $headerNav.toggleClass('open');
  $(this).toggleClass('is-active');
  $header.toggleClass('open')
});

// Add header height as css var.
function setMenuHeightVar() {
  $body.css('--header-height', $header.height() + 'px');
}

// Do this on resize
$(window).on('resize load', function() {
  setMenuHeightVar()
  if ($(window).outerWidth() > 1200) {
    $html.removeClass('open');
    $headerNav.removeClass('open');
    $header.removeClass('open');
    $previousItem.removeClass('menu-item-transition');
    $headerMenu.attr('style', '');
  }
})

$('.site-header .sub-menu-back').click(function () {
  const $parentLi = $(this).closest('li.nav-column');
  const level = $(this).attr('data-backlevel');
  if ($parentLi.length) {
    const newLevel = parseInt(level) - 1;
    NavigateMobileSubMenu(newLevel);
  } else {
    NavigateMobileSubMenu(level);
  }
});
